<template>
  <g>
    <svg:style>
      .cls-4, .cls-5 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 71.08" 
      :y1="doorTopHeight1 + 108.61" 
      :x2="doorLeftWidth1 + 71.08" 
      :y2="doorTopHeight1 + 51.07" 
      xlink:href="#handle-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="R01">
      <circle id="inox" v-if="showInox" data-name="inox" class="cls-4" :cx="`${doorLeftWidth1 + 71.08}`" :cy="`${doorTopHeight1 + 79.84}`" r="28.77"/>
      <circle filter="url(#inset-shadow)" id="glass" class="cls-5" :cx="`${doorLeftWidth1 + 71.08}`" :cy="`${doorTopHeight1 + 79.84}`" r="23.84"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],

  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
